import * as React from "react";
import styled from "styled-components";
import { GtmDataLayer, ToGtmDataLayer, WmkLink } from "wmk-link";
import { colors } from "../../../../vars/palette";
import { paths } from "../../../../vars/paths";
import Theme from "../../../../vars/ThemeOptions";
import { WmkImage } from "wmk-image";
import { Blog } from "../../../../classes/Blog";

const TitleStyle = styled.div`
  h3 {
    font-family: ${Theme.font.head};
    font-weight: bold;
  }
  a {
    color: ${colors.hover("text", 0.3, true)};
    transition: all 0.3s ease;
    &:hover {
      color: ${colors.hex("secondary")};
      text-decoration: none;
      transition: all 0.3s ease;
    }
  }
`;

const Link = ({
  blog,
  toDataLayer,
  children,
  gtmDataLayer,
  className,
  isTitle,
}) => {
  const blogPath = paths.rel("blogs", blog.slug);
  const { title } = blog;

  return (
    <WmkLink
      to={blogPath}
      className={className}
      onClick={() => {
        if (gtmDataLayer) {
          gtmDataLayer.push({
            event: "excerpt_click",
            excerpt_path: blogPath,
            excerpt_title: title,
          });
          gtmDataLayer.push(toDataLayer);
        }
      }}
    >
      {children}
    </WmkLink>
  );
};

const ExcerptImage = styled.div`
  position: relative;
  border-radius: 3px;
  overflow: hidden;
  .the-blog-image,
  .the-blog-image img {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
`;

export const Thumbnail = ({
  blog,
  toDataLayer,
  gtmDataLayer,
}: {
  blog: Blog;
  toDataLayer: ToGtmDataLayer;
  gtmDataLayer: GtmDataLayer;
}) => {
  return (
    <Link blog={blog} toDataLayer={toDataLayer} gtmDataLayer={gtmDataLayer}>
      <ExcerptImage>
        <WmkImage image={blog.image} crop="square" />
      </ExcerptImage>
    </Link>
  );
};

export const Title = ({
  blog,
  toDataLayer,
  gtmDataLayer,
}: {
  blog: Blog;
  toDataLayer: object;
  gtmDataLayer: object;
}) => {
  const title = blog.title;
  return title ? (
    <TitleStyle>
      <WmkLink
        style={{ textDecoration: "none" }}
        toDataLayer={toDataLayer}
        gtmDataLayer={gtmDataLayer}
        isTitle
        to={blog.to}
      >
        <h3>{title}</h3>
      </WmkLink>
    </TitleStyle>
  ) : null;
};
