import * as React from "react";
import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Blog } from "../../../../classes/Blog";
import GibsonButton from "../../../common/GibsonButton";
import TitleBar from "../../../common/PageTitleBar";
import BlogExcerpts from "../BlogExcerpts";

const PER_PAGE = 6;

const TagPage = ({ blogs, title }: {blogs: Blog[], title: string}) => {
  const [more, setMore] = useState(0);
  return (
    <>
      <TitleBar title={`Blogs about ${title}`} back={"/blog"} />
      <BlogExcerpts
        blogs={blogs.slice(
          0,
          more === 0 ? PER_PAGE : PER_PAGE + more * (PER_PAGE / 2)
        )}
      />
      <Row>
        <Col style={{ display: "flex", justifyContent: "center" }}>
          <GibsonButton
            target="more"
            moreData={{
              more,
              setMore,
              total: blogs.length,
              per: PER_PAGE,
            }}
            direction="right"
          >
            Load More
          </GibsonButton>
        </Col>
      </Row>
    </>
  );
};

export default TagPage;
