import * as React from "react";
import { Excerpt } from "./BlogExcerpt";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Blog } from "../../../classes/Blog";

const ContainerWrap = styled(Container)`
  padding: 2rem;
`;

const BlogExcerpts = ({ blogs }: { blogs: Blog[] }) => {
  return (
    <ContainerWrap>
      <Row>
        {blogs && blogs.length ? (
          blogs.map((blog) => {
            return (
              <Col sm={12} md={6} lg={4} key={blog.slug}>
                <Excerpt blog={blog} />
              </Col>
            );
          })
        ) : (
          <h3>Sorry, no matching blogs found. Try a different search.</h3>
        )}
      </Row>
    </ContainerWrap>
  );
};

export default BlogExcerpts;
