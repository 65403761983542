import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Tags from "../Tags/BlogTags";
import { Title, Thumbnail } from "./BlogExcerptLinks";
import { Credits } from "./BlogExcerptCredits";
import { Blog } from "../../../../classes/Blog";
import { GtmDataLayer, ToGtmDataLayer } from "wmk-link";

const ExcerptWrap = styled.div`
  padding: 0.5rem 0.5rem 2rem 0.5rem;
`;

export const Excerpt = ({
  blog,
  toDataLayer,
}: {
  blog: Blog;
  toDataLayer?: ToGtmDataLayer;
}) => {
  const [gtmDataLayer, setGtmDataLayer] = useState<GtmDataLayer>();
  useEffect(() => {
    setGtmDataLayer(window.dataLayer);
  }, []);
  return (
    <ExcerptWrap className="blog-excerpt" id={`${blog.slug}`}>
      <Thumbnail
        blog={blog}
        toDataLayer={{
          ...toDataLayer,
          params: { ...toDataLayer?.params, excerpt_element: "thumbnail" },
        }}
        gtmDataLayer={gtmDataLayer}
      />
      <Tags tags={blog.tags} reverse />
      <Title
        blog={blog}
        toDataLayer={{
          ...toDataLayer,
          params: { ...toDataLayer?.params, excerpt_element: "heading" },
        }}
        gtmDataLayer={gtmDataLayer}
      />
      <Credits timestamp={blog.timestamp} author={blog.author} />
    </ExcerptWrap>
  );
};
