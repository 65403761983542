import * as React from "react";
import styled from "styled-components";
import { colors } from "../../../../vars/palette";
import { WmkLink } from "wmk-link";
import { Tag } from "wmk-tag";

const Topics = styled.div<{ reverse?: boolean }>`
  font-size: 0.9rem;
  text-transform: uppercase;
  color: ${({ reverse }) =>
    reverse ? colors.hex("primary") : colors.hover("secondary")};
  padding: 1.5rem 0 0.25rem 0;
  letter-spacing: 2px;
  a {
    text-decoration: none;
    color: ${({ reverse }) =>
      reverse ? colors.hex("text") : colors.hover("white")};
    transition: all 0.3s ease;
    &:hover {
      color: ${({ reverse }) =>
        reverse ? colors.hex("tertiary") : colors.hover("accent")};
      transition: all 0.3s ease;
      text-decoration: none;
    }
  }
`;

const BlogTags = ({ tags, reverse }: { tags: Tag[]; reverse?: boolean }) => {
  return (
    <Topics reverse={reverse}>
      {tags.map((tag, i: number) => {
        const seperator = i !== tags.length - 1 ? ", " : "";
        return (
          <span key={tag.slug + i}>
            <WmkLink to={`/blog/tags/${tag.slug}`}>{tag.name}</WmkLink>
            {seperator}
          </span>
        );
      })}
    </Topics>
  );
};

export default BlogTags;
