import * as React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { BlogAuthor } from "../../../../classes/Blog";
import { colors } from "../../../../vars/palette";
import BlogDate from "../BlogDate";

const AuthorDate = styled(Row)`
  font-size: 0.9rem;
  color: ${colors.hex("text")};
  display: flex;
  align-items: center;
  text-transform: uppercase;
`;

const Seperator = styled.span`
  color: ${colors.hover("secondary")};
  padding: 0 0.33rem;
`;

type creditProps = {
  author: BlogAuthor;
  timestamp: string;
};

export const Credits = ({ author, timestamp }: creditProps) => {
  return (
    <AuthorDate //noGutters={true}
    >
      {author ? (
        <>
          <Col xs="auto">{author.name}</Col>
          <Col xs="auto">
            <Seperator>—</Seperator>
          </Col>
        </>
      ) : null}
      <Col xs="auto">
        <BlogDate timestamp={timestamp} />
      </Col>
    </AuthorDate>
  );
};
