import { graphql } from "gatsby";
import * as React from "react";
import { WmkSeo } from "wmk-seo";
import Layout from "../components/layout/MainLayout";
import { siteTitle, twitter, baseUrl } from "../vars/ThemeOptions";
import TagPage from "../components/routes/Blog/Tags/TagPage";
import { Blogs } from "../classes/Blog";
import { BlogFields } from "../fragments/nodeBlogFields";

const TagNode = ({
  data,
  pageContext,
}: {
  data: { all: { edges: { node: BlogFields }[] } };
  pageContext: { slug: string; name: string };
}) => {
  const allBlogs = new Blogs(data?.all?.edges);
  const { slug, name } = pageContext;
  return (
    <Layout>
      <WmkSeo.Meta
        title={`${name} Blogs`}
        path="/blog/tags/"
        slug={slug}
        description={"Blogs tagged: " + name}
        ogImageUrl={""}
        twitterImageUrl={""}
        twitterHandle={twitter}
        siteTitle={siteTitle}
        baseUrl={baseUrl}
        // graphs={[
        //   {
        //     "@type": "WebPage",
        //     "@id": meta.baseUrl + "/#page-" + slug,
        //     url: meta.baseUrl,
        //     inLanguage: "en-US",
        //     name: title,
        //     isPartOf: {
        //       "@id": meta.baseUrl + "/#website",
        //     },
        //     about: {
        //       "@id": meta.baseUrl + "/#organization",
        //     },
        //     description: metaDescription.metaDescription,
        //   },
        // ]}
      />
      {/* <TagPage blogs={getTaggedBlogs(slug, allBlogs)} title={tag} /> */}
      <TagPage blogs={allBlogs.hash[slug]} title={name} />
    </Layout>
  );
};

export default TagNode;

export const query = graphql`
  query tagQuery($tag: String) {
    all: allContentfulWmkBlogs(filter: { tags: { eq: $tag } }) {
      edges {
        node {
          ...NodeBlogExcerptFields
        }
      }
    }
  }
`;
