import { Img, GatsbyImageData } from "wmk-image";
import { RichText } from "wmk-rich-text";
import { BlogAuthorFields, BlogFields } from "../fragments/nodeBlogFields";
import { Tag } from "wmk-tag";

interface cropSize {
  name: string;
  image: GatsbyImageData;
}

export class BlogAuthor {
  bio?: string;
  job?: string;
  image: Img;
  name: string;
  status?: string;
  url: string;
  constructor(node: BlogAuthorFields) {
    this.bio = node.biography?.biography
    this.job = node.jobTitle
    this.image = new Img(node.mainImage);
    this.name = node.name;
    this.status = node.status
    this.url = node.websiteUrl
  }
}

export class Blog {
  title: string;
  image: Img;
  slug: string;
  timestamp: string;
  author: BlogAuthor;
  tags: Tag[];
  content: RichText;
  to: string;
  updated: string;
  constructor(node: BlogFields) {
    const _node = { ...node };
    const title: string = _node.title;
    const tile: GatsbyImageData = _node.heroImage?.tileImage;
    const og: GatsbyImageData = _node.heroImage?.ogImage;
    const tw: GatsbyImageData = _node.heroImage?.twitterImage;
    const sq: GatsbyImageData = _node.heroImage?.squareImage;
    const tags: string[] = _node?.tags;
    const crops: cropSize[] = [
      { name: "tile", image: tile },
      { name: "ogImage", image: og },
      { name: "twitterImage", image: tw },
      { name: "square", image: sq },
      { name: "tile", image: _node.heroImage?.tileImage },
    ];
    const image = new Img(_node.heroImage);
    crops.forEach((size) => {
      image.addCrop(size.name, size.image);
    });
    this.title = title;
    this.image = image;
    this.slug = _node.slug;
    this.timestamp = _node.publishDate;
    this.author = new BlogAuthor(_node.author);
    this.tags = Array.isArray(tags) ? tags.map((t) => new Tag(t)) : [];
    this.content = new RichText(_node.content);
    this.to = `/blog/${_node.slug}`;
    this.updated = _node.updatedAt;
  }
}

export class Blogs {
  list: Blog[];
  length: number;
  hash: { [tag: string]: Blog[] };
  constructor(edges: { node: BlogFields }[]) {
    const _edges = Array.isArray(edges) ? [...edges] : [];
    const all = Array.isArray(_edges)
      ? _edges.map((e) => new Blog(e.node))
      : [];
    const hash = all.reduce((tagged, blog) => {
      const tags: Tag[] = blog.tags;
      tags.forEach((t) => {
        const slug = t.slug;
        if (!tagged[slug]) {
          tagged[slug] = [];
        }
        tagged[slug].push(blog);
      });
      return tagged;
    }, {} as Record<string, Blog[]>);
    this.list = all;
    this.length = all.length;
    this.hash = hash;
  }
}
