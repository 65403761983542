import * as React from "react";

const BlogDate = ({ timestamp }: { timestamp: string }) => {
  const date = new Date(timestamp);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return (
    <>
      {months[date.getMonth()] +
        " " +
        date.getDate() +
        ", " +
        date.getFullYear()}
    </>
  );
};

export default BlogDate;
