import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Theme from "../../vars/ThemeOptions";
import styled from "styled-components";
import Stripe from "./Stripe";
import { colors } from "../../vars/palette";
import { WmkLink } from "wmk-link";

const TitleBarWrap = styled(Container)`
  padding: 1.25rem 0 1rem 0;
  .page-title {
    color: ${colors.hex("secondary")};
    font-family: ${Theme.font.head};
    text-transform: uppercase;
    margin: 0;
    line-height: 1;
  }
  .row {
    align-items: center;
  }
  .back {
    text-align: right;
  }
`;

const TitleBar = ({
  title,
  back,
  style,
}: {
  title: string;
  back?: string;
  style?: React.CSSProperties;
}) => {
  return (
    <>
      <TitleBarWrap style={style}>
        <Row>
          <Col>
            <h1 className="page-title">{title}</h1>
          </Col>
          {back ? (
            <Col className="back">
              <WmkLink to={back}>Back</WmkLink>
            </Col>
          ) : null}
        </Row>
      </TitleBarWrap>
      <Stripe />
    </>
  );
};

export default TitleBar;
